declare const Pusher: any;
import { Injectable } from '@angular/core';
import { WindowsService } from './windows.service';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  pusher: any;
  channel: any;

  constructor(
    private windowsService: WindowsService
  ) {
    let key = "a431e61a33a316e2f47f";
    const cluster = "eu";
    const host = this.windowsService.getHostname();
    if(host === "app.anymaint.com"){
      key = "311dd3a4c1d339107c51"
    }else if(host === "anymaint-frontend-preprod.herokuapp.com"){
      key = "a849d035aafe7939e3f6"
    }
    this.pusher = new Pusher(key, {
      cluster: cluster,
      encrypted: true
    });

    this.channel = this.pusher.subscribe('anymaint');
  }
}
