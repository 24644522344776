import { timeCalculation } from "../../../shared/comparators/date-comparator";
import { IField } from "../../interfaces/field";

const FieldDefinition: IField[] = [
    {
        name: 'id_num',
        label: "table.pm-calender.table-title.ID",
        type: 'textbox',
        required: true,
        readonly: true,
    },
    {
        name: 'tool.id_num',
        label: "table.pm-calender.table-title.TOOL-ID",
        type: 'textbox',
        required: true,
        readonly: true,
    },
    {
        name: 'tool.name',
        label: "table.pm-calender.table-title.TOOL-NAME",
        type: 'textbox',
        required: true,
        readonly: true,
    },
    {
        name: 'maintenance.id_num',
        label: "table.pm-calender.table-title.PM-ID",
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'maintenance.type.name',
        label: "table.pm-calender.table-title.PM-NAME",
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'maintenance.type.pmKind',
        label: "table.pm-calender.table-title.PM-KIND",
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'maintenance.type.period',
        label: "table.pm-calender.table-title.PM-PERIOD",
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'dueDateMonth',
        label: "table.pm-calender.table-title.PM-MONTH",
        type: 'textbox',
        required: true,
        readonly: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.dueDate ? nodeA.data.dueDate : valueA, nodeB && nodeB.data.dueDate ? nodeB.data.dueDate : valueB, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'dueDateWeek',
        label: "table.pm-calender.table-title.PM-WEEK",
        type: 'textbox',
        required: true,
        readonly: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.dueDate ? nodeA.data.dueDate : valueA, nodeB && nodeB.data.dueDate ? nodeB.data.dueDate : valueB, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'dueDate',
        label: "table.pm-calender.table-title.DUE-DATE",
        type: 'dateonlypicker',
        required: true,
        readonly: false,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.dueDate ? nodeA.data.dueDate : valueA, nodeB && nodeB.data.dueDate ? nodeB.data.dueDate : valueB, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'actualDate',
        label: "table.pm-calender.table-title.ACTUAL-DATE",
        type: 'dateonlypicker',
        required: true,
        readonly: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.actualDate ? nodeA.data.actualDate : valueA, nodeB && nodeB.data.actualDate ? nodeB.data.actualDate : valueB, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'event.id_num',
        label: "table.pm-calender.table-title.EVENT-ID",
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'worker.displayName',
        label: "table.pm-calender.table-title.WORKER-NAME",
        type: 'textbox',
        required: true,
        readonly: true
    },
    {
        name: 'status',
        label: "table.pm-calender.table-title.STATUS",
        type: 'selectdropdown',
        required: false,
        readonly: false,
        section: 'pm-calender',
    },
    {
        name: 'updatedAt',
        label: "table.pm-calender.table-title.DATE-UPDATED",
        type: 'datepicker',
        required: true,
        readonly: true,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(nodeA && nodeA.data.updatedAt ? nodeA.data.updatedAt : valueA, nodeB && nodeB.data.updatedAt ? nodeB.data.updatedAt : valueB, nodeA, nodeB, isInverted);
        }
    },
    {
        name: 'isDeleted',
        label: 'general.DELETED',
        type: "checkbox",
        readonly: false,
        isAdmin: true,
        section: 'events',
        isAdminEditable: true,
    }
];

export default {
    FieldDefinition
}