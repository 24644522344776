import moment from 'moment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isNumber, isString } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { mergeMap, shareReplay } from 'rxjs/operators';
import { DynamicReport } from '../../../core/interfaces/dynamic-report';
import { EventLogs } from '../../../core/interfaces/event-logs';
import { Maintenance } from '../../../core/interfaces/maintenance';
import { Menu } from '../../../core/interfaces/menu';
import { Part } from '../../../core/interfaces/part';
import { SignatureAuditTrail } from '../../../core/interfaces/signature-audit-trail';
import { TaskTransaction } from '../../../core/interfaces/task-transaction';
import { Tool } from '../../../core/interfaces/tool';
import { ToolEvent } from '../../../core/interfaces/tool-event';
import { Transaction } from '../../../core/interfaces/transaction';
import { UserLogs } from '../../../core/interfaces/user-logs';
import { DynamicReportStoreService } from '../../../core/services/dynamic-report-store.service';
import { EventLogsStoreService } from '../../../core/services/event-logs-store.service';
import { EventsStoreService } from '../../../core/services/events-store.service';
import { MenuStoreService } from '../../../core/services/menu-store.service';
import { PartsStoreService } from '../../../core/services/parts-store.service';
import { PreventiveStoreService } from '../../../core/services/preventive-store.service';
import { SignatureAuditTrailsService } from '../../../core/services/signature-audit-trails.service';
import { SlaStoreService } from '../../../core/services/sla-store.service';
import { TaskTransactionStoreService } from '../../../core/services/task-transaction-store.service';
import { ToolsStoreService } from '../../../core/services/tools-store.service';
import { TransactionssStoreService } from '../../../core/services/transactions-store.service';
import { UserLogsStoreService } from '../../../core/services/user-logs-store.service';
import { timeCalculation } from '../../../shared/comparators/date-comparator';

@Injectable({
  providedIn: 'root'
})
export class DynamicReportService {

  key$: BehaviorSubject<string> = new BehaviorSubject(null);
  menu$: Observable<Menu>;
  dynamicReport$: Observable<DynamicReport>;
  dynamicReportColumnsSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  dynamicReportColumns$: Observable<any[]> = this.dynamicReportColumnsSubject.pipe(
    shareReplay(1)
  );
  dynamicReportDataSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  dynamicReportData$: Observable<any[]> = this.dynamicReportDataSubject.pipe(
    shareReplay(1)
  );;

  constructor(
    private menuStoreService: MenuStoreService,
    private router: Router,
    private dynamicReportStoreService: DynamicReportStoreService,
    private toolsStoreService: ToolsStoreService,
    private preventiveStoreService: PreventiveStoreService,
    private eventsStoreService: EventsStoreService,
    private partsStoreService: PartsStoreService,
    private transactionssStoreService: TransactionssStoreService,
    private taskTransactionStoreService: TaskTransactionStoreService,
    private userLogsStoreService: UserLogsStoreService,
    private signatureAuditTrailsService: SignatureAuditTrailsService,
    private eventLogsStoreService: EventLogsStoreService,
    private slaStoreService: SlaStoreService,
  ) {

    this.menu$ = this.key$.pipe(
      mergeMap(key => this.menuStoreService.getMenu(key)),
      shareReplay(1),
    );

    combineLatest([this.menuStoreService.menus$, this.key$, this.menuStoreService.menuFetched]).subscribe(([menus, key, menuFetched]) => {
      if(key && menuFetched){
        const mn = menus.find(m => m.key == key);
        if(!mn){
          this.router.navigate(['main', 'maintenance']);
        }else{
          this.dynamicReportColumnsSubject.next([]);
        }
      }
    })

    this.dynamicReport$ = this.key$.pipe(
      mergeMap(key => this.dynamicReportStoreService.getByKey(key)),
      shareReplay(1),
    );

    this.dynamicReport$.subscribe(report => {
      if(report){
        this.fetchData(report._id, report.criteria.table, report.reportType);
        if(report.reportType != 'query'){
          const fields = report.criteria.search.map(s => s.field);
          switch (report.criteria.table) {
            case 'tool':
              this.toolsStoreService.columns$.subscribe(col => {
                const columns = col.filter(c => fields.includes(c.colId));
                this.dynamicReportColumnsSubject.next(columns);
              })
              break;
            case 'pm':
              this.preventiveStoreService.columns$.subscribe(col => {
                const columns = col.filter(c => fields.includes(c.colId));
                console.log( 'columns', columns );
                this.dynamicReportColumnsSubject.next(columns);
              })
              break;
            case 'history':
              this.eventsStoreService.columns$.subscribe(col => {
                const columns = col.filter(c => fields.includes(c.colId));
                this.dynamicReportColumnsSubject.next(columns);
              })
              break;
            case 'inventory':
              this.partsStoreService.columns$.subscribe(col => {
                const columns = col.filter(c => fields.includes(c.colId));
                this.dynamicReportColumnsSubject.next(columns);
              })
              break;
            case 'transaction':
              this.transactionssStoreService.columns$.subscribe(col => {
                const columns = col.filter(c => fields.includes(c.colId));
                this.dynamicReportColumnsSubject.next(columns);
              })
              break;
            case 'task-transaction':
              this.taskTransactionStoreService.columns$.subscribe(col => {
                const columns = col.filter(c => fields.includes(c.colId));
                this.dynamicReportColumnsSubject.next(columns);
              })
              break;
            case 'user-logs':
              this.userLogsStoreService.columns$.subscribe(col => {
                const columns = col.filter(c => fields.includes(c.colId));
                this.dynamicReportColumnsSubject.next(columns);
              })
              break;
            case 'signature-at':
              this.signatureAuditTrailsService.columns$.subscribe(col => {
                const columns = col.filter(c => fields.includes(c.colId));
                this.dynamicReportColumnsSubject.next(columns);
              })
              break;
            case 'history-at':
              this.eventLogsStoreService.columns$.subscribe(col => {
                const columns = col.filter(c => fields.includes(c.colId));
                this.dynamicReportColumnsSubject.next(columns);
              })
              break;
            case 'sla':
              this.slaStoreService.columns$.subscribe(col => {
                const columns = col.filter(c => fields.includes(c.colId));
                this.dynamicReportColumnsSubject.next(columns);
              })
              break;
            default:
              break;
          }
        }
      }
    })
  }

  fetchData = (id:string, table:string, reportType:string) => {
    this.dynamicReportStoreService.getDataById(id).subscribe((data:any) => {
      if(reportType == 'query'){
        if(data.length > 0){
          const col = Object.keys(data[0]).filter(k => k != '_id').map((key:string) => {
            const c =  {
              colId: key,
              field: key,
              headerName: key,
              editable: false,
              cellClass: '',
              maxWidth: 400,
              hide: false,
              comparator: undefined,
              minWidth: undefined,
              filter: 'agSetColumnFilter',
            }
            if(moment(data[0][key], "DD-MM-YYYY HH:mm").isValid()){
              return {
                ...c,
                comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                  const aValue = valueA ? moment(valueA, "DD-MM-YYYY HH:mm") : null;
                  const bValue = valueB ? moment(valueB, "DD-MM-YYYY HH:mm") : null;
                  return timeCalculation(aValue, bValue, nodeA, nodeB, isInverted);
                }
              };
            }
            return c;
          });
          this.dynamicReportColumnsSubject.next(col);
        }
      }else{
        switch (table) {
          case 'tool':
            data = data.map(tool => new Tool(tool)).map(tool => this.toolsStoreService.remapTool(tool, this.toolsStoreService.getToolsArray()))
            break;
          case 'pm':
            data = data.map(pm => new Maintenance(pm)).map(pm => this.preventiveStoreService.remapMaintenance(pm))
            data = this.preventiveStoreService.enrichMaintenance(data);
            break;
          case 'history':
            data = data.map(event => new ToolEvent(event)).map(event => this.eventsStoreService.remapEvent(event));
            data = this.eventsStoreService.normalizeData(data);
            break;
          case 'inventory':
            data = data.map(part => new Part(part));
            break;
          case 'transaction':
            data = data.map(transaction => new Transaction(transaction));
            break;
          case 'task-transaction':
            data = data.map(taskTransaction => new TaskTransaction(taskTransaction));
            break;
          case 'user-logs':
            data = data.map(userLogs => new UserLogs(userLogs));
            break;
          case 'signature-at':
            data = data.map(signatureAt => new SignatureAuditTrail(signatureAt));
            break;
          case 'history-at':
            data = data.map(eventLog => new EventLogs(eventLog)).map(eventLog => this.eventLogsStoreService.remapEvent(eventLog));
            data = this.eventLogsStoreService.normalizeData(data);
            break;
          case 'sla':
            data = data.map(event => new ToolEvent(event)).map(event => this.eventsStoreService.remapEvent(event));
            data = this.eventsStoreService.normalizeData(data);
            break;
          default:
            break;
        }
      }
      this.dynamicReportDataSubject.next(data);
    })
  }

  setKey(key:string) {
    this.key$.next(key);
  }

  getKey() {
    return this.key$.getValue();
  }
}
