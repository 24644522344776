import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_CHAT_GPT } from '../../interfaces/general';

@Injectable({
  providedIn: 'root'
})
export class OpenaiBackendService {
  url = '/api/openai';

  constructor(
    private http: HttpClient
  ) { }

  fetchAllToolImages = (toolId: string) => {
    return this.http.post(`${this.url}/fetch-all-tool-images/${toolId}`, {});
  }

  ask = (search: string, tool: string) => {
    return this.http.post(`${this.url}/ask`, { search: search, tool: tool });
  }

  simulator = (sentence: BASE_CHAT_GPT) => {
    return this.http.post(`${this.url}/simulator`, sentence);
  }

}
