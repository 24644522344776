import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Measurement } from "../../interfaces/measurement";

@Injectable({
  providedIn: "root",
})
export class MeasurementsBackendService {
  url = "/api/measurements";
  constructor(private http: HttpClient) {}

  list() {
    return this.http.get<Array<Measurement>>(`${this.url}`);
  }

  options() {
    return this.http.get<Array<Measurement>>(`${this.url}/options`);
  }

  optionsByTool(id: string) {
    return this.http.get<Array<Measurement>>(`${this.url}/options-by-tool/${id}`);
  }

  manualMeasurements(toolId: string) {
    return this.http.get<Array<Measurement>>(`${this.url}/manual-measurements/${toolId}`);
  }

  listByTool(tools: string[]) {
    return this.http.post<Array<Measurement>>(`${this.url}/list-by-tools`, {
      tools: tools,
    });
  }

  manualListByTool(tools: string[]) {
    return this.http.post<Array<Measurement>>(`${this.url}/manual-list-by-tools`, {
      tools: tools,
    });
  }

  duplicate(id: string[]) {
    return this.http.post<Measurement[]>(`${this.url}/duplicate`, { id: id });
  }

  create(measurement: Measurement) {
    return this.http.post<Measurement>(`${this.url}`, measurement);
  }

  update(measurement: Measurement, measurementId: string) {
    return this.http.put<Measurement>(
      `${this.url}/${measurementId}`,
      measurement
    );
  }

  delete(measurementId: string) {
    return this.http.delete(`${this.url}/${measurementId}`);
  }
}
