import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ICustomField } from '../../interfaces/custom-field';
import { Company } from '../../interfaces/company';
import { Observable } from 'rxjs';
import { FileUploader } from '../../utils/ng2-file-upload';
import { AuthService } from '../../../routes/user/auth.service';
import { EmailColumns } from '../../interfaces/email-columns';

@Injectable({
  providedIn: 'root'
})
export class CompaniesBackendService {
  url = '/api/companies';
  constructor(private http: HttpClient, private authService: AuthService) { }

  list(){
    return this.http.get<Array<Company>>(this.url)
  }

  get(companyId: string){
    return this.http.get<Company>(`${this.url}/${companyId}`)
  }

  update(company: any) {
    return this.http.put<Company>(`${this.url}/${company._id}`, company);
  }

  updateEmailSettings(company: any) {
    return this.http.put<Company>(`${this.url}/email-settings/${company._id}`, company);
  }

  delete = (company: any) => this.http.delete(`${this.url}/${company._id}`, {});

  generateQrCode = (companyId: string) => this.http.post(`${this.url}/generate-qr-code/${companyId}`, {});

  uploadPicture(files) {
    return new Observable((observer) => {
      const uploader = new FileUploader({
        url: `${this.url}/uploadPicture`,
        method: 'POST',
        authToken: 'Bearer ' + this.authService.getToken(),
        headers: [
          { name: 'Company', value: this.authService.getCompany() }
        ],
        autoUpload: true
      });
      uploader.onCompleteItem = (item, response, status) => {
        if (status > 400) {
          return observer.error(new HttpErrorResponse({error: response, status: status}));
        }
        observer.next(JSON.parse(response));
        observer.complete();
      };
      uploader.addToQueue(files);
    });

  }

  uploadEula = (company, files) => {
    return new Observable((observer) => {
      const uploader = new FileUploader({
        url: `${this.url}/${company._id}/uploadEula`,
        method: 'POST',
        authToken: 'Bearer ' + this.authService.getToken(),
        headers: [
          { name: 'Company', value: this.authService.getCompany() }
        ],
        autoUpload: true
      })

      uploader.onCompleteItem = (item, response, status) => {
        if (status > 400) {
          return observer.error(new HttpErrorResponse({ error: response, status: status }))
        }
        observer.next(JSON.parse(response))
        observer.complete()
      }

      uploader.addToQueue(files)
    })
  }

  requestClose = () => this.http.post(`${this.url}/request/close`, {});
  generateKeys = () => this.http.post(`${this.url}/generate-keys`, {});
  requestDelete = () => this.http.post(`${this.url}/request/delete`, {});
  requestReceipt = () => this.http.post(`${this.url}/request/receipt`, {});
  requestShare = () => this.http.post(`${this.url}/request/share`, {});
  requestUnshare = () => this.http.post(`${this.url}/request/unshare`, {});
  generatePpt = () => this.http.post(`${this.url}/generate-ppt`, {});
  downloadPpt = () => this.http.post(`${this.url}/download-ppt`, {});
  emailColumns = () => this.http.get<EmailColumns>(`${this.url}/email-columns`, {});
  getScheduledReports = () => this.http.get<EmailColumns[]>(`${this.url}/scheduled-reports`, {});
  addScheduledReport(report: EmailColumns) {
    return this.http.post<EmailColumns>(`${this.url}/scheduled-reports`, report);
  }
  updateScheduledReport(report: EmailColumns) {
    return this.http.put<EmailColumns>(`${this.url}/scheduled-reports/${report._id}`, report);
  }
  deleteScheduledReport(report: EmailColumns) {
    return this.http.delete(`${this.url}/scheduled-reports/${report._id}`);
  }
  duplicateScheduledReport(report: EmailColumns) {
    return this.http.post(`${this.url}/scheduled-reports/duplicate/${report._id}`, {});
  }
}
