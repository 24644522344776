import { GeneralService } from './../../../core/services/general.service';
import { SelectFileComponent } from './../select-file/select-file.component';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { FilesStoreService } from '../../../core/services/files-store.service';
import { CommonService } from '../../../core/services/common.service';

@Component({
  selector: 'app-file-renderer',
  template: `
    <div style="display: flex; align-items: center;">
      <button class="btn btn-primary" type="button" (click)="onClick($event)">{{ "general.UPLOAD-FILE" | translate }}</button>
      <div class="m-1">/</div>
      <button class="btn btn-primary" type="button" (click)="onSelect($event)">{{ "general.SELECT-FILE" | translate }}</button>
      <div *ngIf="value">
        <a href="#" (click)="viewFile($event)" class="m-1">{{ "general.SHOW-FILE" | translate }}</a>
      </div>
      <input
        class="hidden"
        type="file"
        hidden
        #uploadctl
        (change)="uploadPicture($event)"
        click-stop-propagation
      />
    </div>
    `
})

export class FileRendererComponent implements ICellRendererAngularComp {
  params;
  value;
  @ViewChild('uploadctl') uploadctl: ElementRef;

  constructor(
    private filesStoreService: FilesStoreService,
    private modal: BsModalService,
    private generalService: GeneralService,
    public commonService: CommonService,
  ) {
  }

  agInit(params): void {
    this.params = params;
    this.value = this.params.value;
  }

  refresh(params?: any): boolean {
    return false;
  }

  getValue(): any {
    return this.params.value;
  }

  onSelect($event) {
    const observable = new Observable<string>(observer => {
      this.modal.show(SelectFileComponent,
        {
          keyboard: false,
          ignoreBackdropClick: true,
          initialState: {
            observer: observer,
          },
          class: "modal-lg"
        })
    });
    observable.subscribe(result => {
      if(result){
        let colId = this.params.column.colId;
        this.params.node.setDataValue(colId, result);
      }
    })
  }

  viewFile = ($event: Event) => {
    $event.stopPropagation();
    $event.preventDefault();
    if(this.value) this.generalService.viewFile(this.value);
  }

  onClick($event) {
    this.uploadctl.nativeElement.click()
  }

  uploadPicture = (event) => {
    if (!event.target.files.length || !event.target.files[0].name) {
      return
    }
    if(this.commonService.checkFileExtention(event)){
      this.filesStoreService.uploadCustomFiles(event.target.files).subscribe((data) => {
        if(data.file && data.file.id){
          let colId = this.params.column.colId;
          this.params.node.setDataValue(colId, data.file.id);
        }
      })
    }
  }
}