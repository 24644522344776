import { IField } from "../../interfaces/field";
import dateComparator from '../../../shared/comparators/date-comparator';

const FieldDefinition: IField[] = [
    {
        name: 'id_num',
        label: 'table.files.table-title.FILE-ID',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'tool.name',
        label: 'table.files.table-title.TOOL-NAME',
        type: 'textbox',
        required: false,
        readonly: true,
        valueGetter: params => {
            return params.data && params.data.tool ? params.data.tool.name : (params.data.entity === "Setup-Wizard" ? "Setup Wizard" : "");
        }
    },
    {
        name: 'tool.id_num',
        label: 'table.pm.table-title.TOOL-ID',
        type: 'textbox',
        required: false,
        readonly: true,
        hide: true,
    },
    {
        name: 'name',
        label: 'table.files.table-title.FILE-NAME',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'createdAt',
        label: 'table.files.table-title.DATE-UPLOADED',
        type: 'datepicker',
        required: false,
        readonly: true,
        comparator: dateComparator("DD.MM.YYYY HH:mm")
    },
    {
        name: 'worker.id_num',
        label: 'table.files.table-title.WORKER-ID',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'worker.displayName',
        label: 'table.files.table-title.UPLOADED-BY',
        type: 'textbox',
        required: false,
        readonly: true,
    },
    {
        name: 'comment',
        label: 'table.files.table-title.COMMENT',
        type: 'textbox',
        required: false,
        readonly: false,
    },
    {
        name: 'deleted',
        label: 'general.DELETED',
        type: "checkbox",
        readonly: false,
        isAdmin: true
    }
];

export default
    {
        FieldDefinition
    }