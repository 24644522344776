import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileUploader } from '../../utils/ng2-file-upload';
import { AuthService } from '../../../routes/user/auth.service';
import { Tool } from '../../interfaces/tool';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ToolsBackendService {
  url = '/api/tools';

  constructor(private http: HttpClient, private authService: AuthService, private router: Router) {
  }

  list(lastFetched: string = ''): Observable<Tool[]> {
    return this.http.get<Tool[]>(`${this.url}?lastFetched=${lastFetched}`);
  }

  deletedList(): Observable<Tool[]> {
    return this.http.get<Tool[]>(`${this.url}?deleted=true`);
  }

  search(search: object): Observable<Tool[]> {
    return this.http.post<Tool[]>(`${this.url}/search`, search);
  }

  getOne(id) {
    return this.http.get(`${this.url}/${id}`);
  }

  create(tool: Tool) {
    return this.http.post<Tool>(this.url, tool);
  }

  rotatePicture(toolId: string) {
    return this.http.post(`${this.url}/rotate-image/${toolId}`, {});
  }

  update(tool: Tool) {
    return this.http.put<Tool>(`${this.url}/${tool._id}`, tool);
  }

  updateMultiple(tools: Tool[]): Observable<Tool[]> {
    return this.http.post<Tool[]>(`${this.url}/update-multiple`, { tools: tools });
  }

  delete(id: String) {
    return this.http.delete(`${this.url}/${id}`, { body: { url: this.router.url } });
  }

  listColumns() {
    return this.http.get(`${this.url}/columns`);
  }

  addColumn(columnName: string) {
    return this.http.post(`${this.url}/columns`, { name: columnName });
  }

  deleteColumn(columnName: string) {
    return this.http.delete(`${this.url}/columns/${columnName}`);
  }

  listFiles(id, page, limit) {
    return this.http.get<Array<any>>(`${this.url}/files/${id}/${page}/${limit}`);
  }

  listParts(id: number) {
    return this.http.get<Array<any>>(`${this.url}/parts/${id}`);
  }

  listFilesCount(id): Observable<{ files: Number }> {
    return this.http.get<{ files: Number }>(`${this.url}/files-count/${id}`);
  }

  uploadPicture(id, files) {
    return new Observable((observer) => {
      const uploader = new FileUploader({
        url: `${this.url}/${id}`,
        method: 'POST',
        authToken: 'Bearer ' + this.authService.getToken(),
        headers: [
          { name: 'Company', value: this.authService.getCompany() }
        ],
        autoUpload: true
      });
      uploader.onCompleteItem = (item, response, status) => {
        if (status > 400) {
          return observer.error(new HttpErrorResponse({ error: response, status: status }));
        }
        observer.next(JSON.parse(response));
        observer.complete();
      };
      uploader.addToQueue(files);
    });

  }

  choosePicture(toolId: string, url: string) {
    return this.http.post(`${this.url}/choose-picture/${toolId}`, { url: url });
  }
}
