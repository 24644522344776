import { MaintenanceTypesStoreService } from "./maintenance-types-store.service";
import { DepartmentsStoreService } from "./departments-store.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, forkJoin } from "rxjs";
import { List } from "immutable";
import { map, shareReplay, tap } from "rxjs/operators";
import { GroupUpdateMaintenance, Maintenance } from "../interfaces/maintenance";
import { PreventiveBackendService } from "./backend/preventive-backend.service";
import { AuthService } from "../../routes/user/auth.service";
import { EventsStoreService } from "./events-store.service";
import FieldDefinition from "../constants/field-definitions/pm";
import { ToolEvent } from "../interfaces/tool-event";
import { CustomFieldsStoreService } from "./custom-fields-store.service";
import { groupBy, isObject, isString, uniqBy } from "lodash";
import { CommonService } from "./common.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { PmChecklistDetailComponent } from "../../routes/main/pm/pm-checklist-detail/pm-checklist-detail.component";
import { Router } from "@angular/router";
import { timeCalculation } from "../../shared/comparators/date-comparator";
import { MAINTENANCE_OBJECT } from "../interfaces/general";
import { Tool } from "../interfaces/tool";
import { UsersStoreService } from "./users-store.service";
import { TranslationsStoreService } from "./translations-store.service";
import { pmKind } from "../constants/pmKind";
import { TranslateService } from "@ngx-translate/core";
import { PmCalenderStoreService } from "./pm-calender-store.service";
import { PM_CALENDAR_STATUS } from "../constants/enums/enums";
import moment, { Moment } from "moment-timezone";
import Bluebird from "bluebird";

@Injectable({
  providedIn: "root",
})
export class PreventiveStoreService {
  private preventiveSubject: BehaviorSubject<List<Maintenance>> =
    new BehaviorSubject(List([]));
  private preventiveDeletedSubject: BehaviorSubject<List<Maintenance>> =
    new BehaviorSubject(List([]));
  private activePreventiveSubject: BehaviorSubject<List<Maintenance>> =
    new BehaviorSubject(List([]));
  public readonly maints$: Observable<List<Maintenance>>; //  = this.preventiveSubject.asObservable();
  public readonly activeMaints$: Observable<List<Maintenance>> =
    this.activePreventiveSubject.asObservable(); //  = this.preventiveSubject.asObservable();
  public readonly maintsDeleted$: Observable<List<Maintenance>> =
    this.preventiveDeletedSubject.asObservable();

  public toolWiseMaintenanceSubject: BehaviorSubject<MAINTENANCE_OBJECT> =
    new BehaviorSubject({});

  private columnsSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public readonly columns$: Observable<any[]>;

  private lastFetchedTime: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private eventService: EventsStoreService,
    private customFieldsService: CustomFieldsStoreService,
    private eventsStoreService: EventsStoreService,
    private backend: PreventiveBackendService,
    private usersStoreService: UsersStoreService,
    private departmentsStoreService: DepartmentsStoreService,
    private maintenanceTypesStoreService: MaintenanceTypesStoreService,
    private auth: AuthService,
    private commonService: CommonService,
    private modal: BsModalService,
    private translateService: TranslateService,
    private translationsStoreService: TranslationsStoreService,
    private router: Router,
    private pmCalenderStoreService: PmCalenderStoreService
  ) {
    auth.user$.subscribe((user) => {
      if (!user || !user._id || user.isRoot()) {
        this.preventiveSubject.next(List([]));
        return;
      }
      this.load();
    });

    this.maints$ = combineLatest([
      this.preventiveSubject,
      this.pmCalenderStoreService.pmCalenders$,
    ]).pipe(
      map(([maints, pmCalendars]) =>
        this.enrichMaintenance(maints, pmCalendars)
      ),
      shareReplay(1)
    );

    combineLatest([this.usersStoreService.users$]).subscribe(([users]) => {
      const maintenances = this.preventiveSubject.getValue().toArray();
      if (maintenances.length > 0) {
        const m = maintenances.map((maintenance: Maintenance) =>
          this.remapMaintenance(maintenance)
        );
        this.preventiveSubject.next(List(m));
      }
    });

    const hasPmCalender = this.commonService.hasPmCalenderPermission();
    const hasMeasurements = this.commonService.hasMeasurementsPermission();

    this.columns$ = combineLatest([
      this.auth.user$,
      this.customFieldsService.get("pm"),
      this.translationsStoreService.languageCode$,
    ]).pipe(
      map(([user, columns, languageCode]) => {
        const hiddenRegularColumns = columns
          .filter((c) => c.isRegularColumn)
          .toArray();
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(
          (col) =>
            (col.name != "company.name" || user.isRoot()) &&
            (col.name != "isDeleted" || user.isAdminOrToolAdmin()) &&
            (col.name != "isPMCalender" || hasPmCalender) &&
            (col.name != "closeAfter" || hasPmCalender) &&
            (col.name != "measurementsObject.id_num" || hasMeasurements) &&
            (col.name != "measurements" || hasMeasurements) &&
            (col.name != "taskProcedure.id_num" ||
              this.commonService.hasProceduresAndTasksPermission())
        ).map((col) => {
          if (col.name === "group") {
            col.name = "group.name";
            col.readonly = true;
            col.type = "textbox";
          }
          const f = hiddenRegularColumns.find((c) => c.name === col.name);
          if (f) {
            col.hide = f.hide;
            col.required = f.required;
            col.dontPresentOnModal = f.dontPresentOnModal;
            col.preventEditOnModal = f.preventEditOnModal;
            col.chartDataType = f.chartDataType;
            col.rules = f.rules;
            col.isRegularColumn = f.isRegularColumn;
          }
          return col;
        });
        const fields = columns.toArray().filter((c) => !c.isRegularColumn);
        const col = this.customFieldsService.toColumnDef(defaults, {
          rowSelector: "id_num",
          table: "pm",
          format: this.formatCell.bind(this),
        });
        const custom = this.customFieldsService.toColumnDef(fields, {
          format: this.customFieldsService.formatCellCustomField.bind(this),
        });
        return col.concat(custom);
      })
    );

    this.mapToolWiseMaintenance();
  }

  load() {
    const time = moment().tz("UTC").format("YYYY-MM-DD HH:mm:ss");
    this.backend
      .list(this.lastFetchedTime.getValue())
      .pipe(
        map((maintenances) =>
          maintenances.map((maintenance) => new Maintenance(maintenance))
        ),
        map((maintenances) =>
          maintenances.map((maintenance: Maintenance) =>
            this.remapMaintenance(maintenance)
          )
        )
      )
      .subscribe((maints: Array<Maintenance>) => {
        this.lastFetchedTime.next(time);
        if (!this.lastFetchedTime.getValue()) {
          this.preventiveSubject.next(List(maints));
        } else {
          const prev = this.preventiveSubject.getValue().toArray();
          const data = uniqBy([...maints, ...prev], "_id");
          this.preventiveSubject.next(List(data));
        }
      });
  }

  deletedList() {
    if (this.preventiveDeletedSubject.getValue().toArray().length === 0) {
      this.subDeletedList().subscribe((maints: Array<Maintenance>) => {
        this.preventiveDeletedSubject.next(List(maints));
      });
    }
  }

  subDeletedList() {
    return this.backend.listDeleted().pipe(
      map((maintenances) =>
        maintenances.map((maintenance) => new Maintenance(maintenance))
      ),
      map((maintenances) =>
        maintenances.map((maintenance: Maintenance) =>
          this.remapMaintenance(maintenance)
        )
      )
    );
  }

  public getDeletedPmArray() {
    return this.preventiveDeletedSubject.getValue().toArray();
  }

  mapToolWiseMaintenance = () => {
    combineLatest([
      this.eventsStoreService.toolWiseEventSubject,
      this.maints$,
    ]).subscribe(([events, maints]) => {
      let toolMaintenance: any = {};
      const groupedMaintenances = groupBy(
        maints
          .filter((m) => {
            const t = m.tool as Tool;
            return !m.isDeleted && !m.isFreeze && t && !t.isDeleted;
          })
          .toArray(),
        "tool._id"
      );
      for (let i in groupedMaintenances) {
        const severity = [];
        const m = groupedMaintenances[i];
        const toolEvent = events[i];
        const eventMaintenances = toolEvent ? toolEvent.maintenances : [];
        const maintsSeverity = m.reduce((acc, curr) => {
          const s = Maintenance.getMaintenanceBadgeSeverity(curr);
          if (!eventMaintenances.includes(curr._id)) {
            severity[s] = (severity[s] || 0) + 1;
          }
          return Math.max(acc, s);
        }, 0);
        const remnantSeverity = m
          .filter((m) => eventMaintenances.includes(m._id))
          .reduce((acc, curr) => {
            const s = Maintenance.getMaintenanceBadgeSeverity(curr);
            if (!eventMaintenances.includes(curr._id)) {
              severity[s] = (severity[s] || 0) + 1;
            }
            return Math.max(acc, Maintenance.getMaintenanceBadgeSeverity(curr));
          }, 0);
        toolMaintenance[i] = {
          status: Math.max(maintsSeverity, remnantSeverity),
          severity: severity,
        };
      }
      this.toolWiseMaintenanceSubject.next(toolMaintenance);
    });
  };

  getToolWiseMaintenance = (toolId: string) => {
    const tools = this.toolWiseMaintenanceSubject.getValue();
    return typeof tools[toolId] != "undefined" ? tools[toolId] : 0;
  };

  search(search: object) {
    return this.backend.search(search).pipe(
      map((maintenances) =>
        maintenances.map((maintenance) => {
          maintenance.lastMaintenance = maintenance.lastPM
            ? maintenance.lastPM
            : null;
          maintenance.nextMaintenance = Maintenance.nextPMDate(
            maintenance,
            this.commonService.getCompany()
          );
          return new Maintenance(maintenance);
        })
      ),
      map((maintenances) =>
        maintenances.map((maint) => this.remapMaintenance(maint))
      )
    );
  }

  create(maint: Maintenance) {
    return this.backend.create(this.customFieldsService.includeUrl(maint)).pipe(
      map((maint) => new Maintenance(maint)),
      map((maint) => this.remapMaintenance(maint)),
      tap((ev) => {
        this.preventiveSubject.next(
          this.preventiveSubject.getValue().unshift(ev)
        );
      })
    );
  }

  getList() {
    return this.preventiveSubject.getValue();
  }

  getMaintenanceById(maintenanceId: string) {
    const maintenances = this.getList().toArray();
    const maintenanceIndex = maintenances.findIndex(
      (maintenance) => maintenance._id === maintenanceId
    );
    return maintenanceIndex > -1 ? maintenances[maintenanceIndex] : null;
  }

  update(maint: Maintenance) {
    return this.backend.update(this.customFieldsService.includeUrl(maint)).pipe(
      map((m) => new Maintenance(m)),
      map((m) => this.remapMaintenance(m)),
      tap((m) => {
        const maintenances = this.preventiveSubject.getValue();
        const idx = maintenances.findIndex(
          (e: Maintenance) => e._id === maint._id
        );
        this.preventiveSubject.next(maintenances.set(idx, m));
      })
    );
  }

  syncMaintenance(id: string) {
    return this.backend.getById(id).pipe(
      map((m) => new Maintenance(m)),
      map((m) => this.remapMaintenance(m)),
      tap((m) => {
        const maintenances = this.preventiveSubject.getValue();
        const idx = maintenances.findIndex((e: Maintenance) => e._id === id);
        if (idx > -1) {
          this.preventiveSubject.next(maintenances.set(idx, m));
        } else {
          this.preventiveSubject.next(maintenances.unshift(m));
        }
      })
    );
  }

  generatePmCalender() {
    return this.backend.generatePmCalender();
  }

  generatePMCalenderTillDate(endDate: string) {
    return this.backend.generatePMCalenderTillDate(endDate);
  }

  delete(maint: Maintenance) {
    const services: Observable<any>[] = [];
    const maintenance = new Maintenance(maint);
    if (
      maintenance.showNotification(this.commonService.getCompany()) &&
      !!maintenance.nextMaintenance
    ) {
      const type = maintenance.type ? maintenance.type.name : maintenance.name;
      const nextEvent: ToolEvent = new ToolEvent({
        tool: maintenance.tool._id,
        maintenance: maintenance._id,
        type: type,
        start: new Date(this.commonService.convertDateForDateObject()),
        finish: new Date(this.commonService.convertDateForDateObject()),
        checklist: {},
      });

      services.push(this.eventService.create(nextEvent));
    } else {
      maintenance.isCompleted = true;
    }

    maintenance.isDeleted = true;

    services.push(this.update(maintenance));
    return forkJoin(services);
  }

  save(event: Maintenance) {
    if (event._id) {
      return this.update(event);
    }
    return this.create(event);
  }

  // noinspection JSMethodCanBeStatic
  remapMaintenance(maint) {
    if (maint.worker) {
      if (isString(maint.worker)) {
        const worker = this.usersStoreService
          .getList()
          .find((u) => u._id == maint.worker);
        if (worker) {
          maint.workerObject = worker;
        } else {
          const workerDepartment = this.departmentsStoreService
            .getList()
            .find((d) => d._id == maint.workerDepartment);
          if (workerDepartment) {
            maint.workerObject = workerDepartment;
          }
        }
      } else {
        if (
          isObject(maint.workerDepartment) &&
          Object.keys(maint.workerDepartment).length > 0
        ) {
          maint.workerObject = maint.workerDepartment || null;
          if (maint.workerObject)
            maint.workerObject.displayName = `${maint.workerObject.name || ""}`;
        } else {
          maint.workerObject = maint.worker || null;
          if (maint.workerObject)
            maint.workerObject.displayName = `${
              maint.workerObject.firstName || ""
            } ${maint.workerObject.lastName || ""} (${
              maint.workerObject.username
            })`;
        }
      }
    }
    if (maint.workerObject) maint.worker = maint.workerObject._id;
    if (maint.department) {
      if (isString(maint.department)) {
        const department = this.departmentsStoreService
          .getList()
          .find((d) => d._id == maint.department);
        if (department) {
          maint.departmentObject = department;
          maint.department = maint.departmentObject._id;
        }
      } else {
        maint.departmentObject = maint.department || {};
        maint.department =
          typeof maint.departmentObject._id != "undefined"
            ? maint.departmentObject._id
            : null;
      }
    }
    if (maint.type && isString(maint.type)) {
      const type = this.maintenanceTypesStoreService
        .getList()
        .find((m) => m._id == maint.type);
      maint.type = type;
    }
    if (maint.taskProcedure && isObject(maint.taskProcedure)) {
      maint.taskProcedureObject = maint.taskProcedure;
      maint.taskProcedure = maint.taskProcedure._id;
    }
    if (maint.inventoryParts && maint.inventoryParts.length > 0 && isObject(maint.inventoryParts[0])) {
      maint.inventoryPartsObject = maint.inventoryParts;
      maint.inventoryParts = maint.inventoryParts.map(
        (p: { _id: string }) => p._id
      );
    }
    if (maint.measurements && maint.measurements.length > 0 && isObject(maint.measurements[0])) {
      maint.measurementsObject = maint.measurements;
      maint.measurements = maint.measurements.map(
        (p: { _id: string }) => p._id
      );
    }
    return maint;
  }

  enrichMaintenance(maints, pmCalendars = List([])) {
    const dateUpdatePms = [];
    const maintenances = maints
      .filter((maint) => !!maint.tool)
      .map((maint) => {
        maint.lastMaintenance = maint.lastPM ? maint.lastPM : null;
        if (this.commonService.hasPmCalenderPermission()) {
          const pmCalendar = pmCalendars
            .toArray()
            .filter((p) => {
              return (
                !p.isDeleted &&
                !p.actualDate &&
                p.maintenance._id == maint._id &&
                p.status != PM_CALENDAR_STATUS.SKIPPED &&
                p.status != PM_CALENDAR_STATUS.DONE &&
                !p.event &&
                p.dueDate
              );
            })
            .sort((a, b) =>
              timeCalculation(a.dueDate, b.dueDate, null, null, false)
            );
          if (pmCalendar.length > 0) {
            if (
              !this.commonService.isSameTwoDates(
                maint.nextPM,
                pmCalendar[0].dueDate
              )
            ) {
              dateUpdatePms.push({
                _id: maint._id,
                id_num: maint.id_num,
                nextPM: pmCalendar[0].dueDate,
                oldNextPM: maint.nextPM,
              });
            }
            maint.nextPM = pmCalendar[0].dueDate;
          }
        }
        maint.nextMaintenance = Maintenance.nextPMDate(
          maint,
          this.commonService.getCompany()
        );
        return maint;
      });
    if (dateUpdatePms.length > 0) {
      this.backend.updatePmDate(dateUpdatePms).subscribe();
    }
    return maintenances;
  }

  getMiantenance(params) {
    this.modal.show(PmChecklistDetailComponent, {
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: {
        maintenance: params.rowData,
      },
      class: "modal-lg",
    });
  }

  getMaint(params) {
    localStorage.setItem("_maint_id", params.rowData.id_num);
    this.router.navigate([
      "/main",
      "maintenance",
      "tool",
      params.rowData && params.rowData.tool ? params.rowData.tool.id_num : "",
    ]);
  }

  formatCell = (col, field) => {
    switch (field.name) {
      case "id_num":
        return {
          ...col,
          cellRenderer: "customClick",
          cellRendererParams: {
            onClick: this.getMaint.bind(this),
            field: field.name,
          },
        };
      case "tool.name":
        return {
          ...col,
          cellRenderer: "customClick",
          cellRendererParams: {
            onClick: this.toolLink.bind(this),
            field: field.name,
          },
        };
      case "checklistItems":
        return {
          ...col,
          cellRenderer: "customClick",
          cellRendererParams: {
            onClick: this.getMiantenance.bind(this),
            field: field.name,
          },
        };
      case "file":
        return {
          ...col,
          cellRenderer: "fileRedirection",
          cellRendererParams: {
            isMultiple: true,
          },
        };
      case "workerObject.username":
        return {
          ...col,
          minWidth: 200,
        };
      case "nextMaintenance":
        return {
          ...col,
          valueFormatter: (params) =>
            params.data.nextMaintenance &&
            this.commonService.getDateTime(params.data.nextMaintenance),
          valueGetter: (params) =>
            params.data.nextMaintenance &&
            this.commonService.getDateTime(params.data.nextMaintenance),
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(
              nodeA && nodeA.data.nextMaintenance
                ? nodeA.data.nextMaintenance
                : null,
              nodeB && nodeB.data.nextMaintenance
                ? nodeB.data.nextMaintenance
                : null,
              nodeA,
              nodeB,
              isInverted
            );
          },
        };
      case "nextMaintenanceMonth":
        return {
          ...col,
          valueFormatter: (params) =>
            params.data.nextMaintenance &&
            this.commonService.getDateMonth(params.data.nextMaintenance),
          valueGetter: (params) =>
            params.data.nextMaintenance &&
            this.commonService.getDateMonth(params.data.nextMaintenance),
        };
      case "nextMaintenanceWeek":
        return {
          ...col,
          valueFormatter: (params) =>
            params.data.nextMaintenance &&
            this.commonService.getDateWeek(params.data.nextMaintenance),
          valueGetter: (params) =>
            params.data.nextMaintenance &&
            this.commonService.getDateWeek(params.data.nextMaintenance),
        };
      case "lastMaintenance":
        return {
          ...col,
          valueFormatter: (params) =>
            params.data.lastMaintenance &&
            this.commonService.getDateTime(params.data.lastMaintenance),
          valueGetter: (params) =>
            params.data.lastMaintenance &&
            this.commonService.getDateTime(params.data.lastMaintenance),
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return timeCalculation(
              nodeA && nodeA.data.lastMaintenance
                ? nodeA.data.lastMaintenance
                : null,
              nodeB && nodeB.data.lastMaintenance
                ? nodeB.data.lastMaintenance
                : null,
              nodeA,
              nodeB,
              isInverted
            );
          },
        };
      case "maintenanceName":
        return {
          ...col,
          valueFormatter: (params) =>
            params.data.type ? params.data.type.name : params.data.name,
        };
      case "type.pmKind":
        return {
          ...col,
          valueFormatter: (params) =>
            params.data.type && params.data.type.pmKind
              ? this.translateService.instant(pmKind[params.data.type.pmKind])
              : null,
          valueGetter: (params) =>
            params.data.type && params.data.type.pmKind
              ? this.translateService.instant(pmKind[params.data.type.pmKind])
              : null,
        };
      default:
        return col;
    }
  };

  toolLink(params) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        `/main/maintenance/tool/${
          params.rowData && params.rowData.tool
            ? params.rowData.tool.id_num
            : ""
        }`,
      ])
    );
    window.open(url, "_blank");
  }

  updateBulk(maintenances: Maintenance[]) {
    return this.backend.updateBulk(maintenances).pipe(
      map((maintenances) =>
        maintenances.map((event: Maintenance) => new Maintenance(event))
      ),
      map((maintenances) =>
        maintenances.map((event: Maintenance) => this.remapMaintenance(event))
      ),
      tap((maintenances: Array<Maintenance>) => {
        maintenances.forEach((maintenance) => {
          const mnt = this.preventiveSubject.getValue();
          const idx = mnt.findIndex(
            (t: Maintenance) => t._id === maintenance._id
          );
          this.preventiveSubject.next(mnt.set(idx, maintenance));
        });
      })
    );
  }

  groupUpdatePM(group: GroupUpdateMaintenance) {
    return this.backend.groupUpdatePM(group).pipe(
      map((maintenances) =>
        maintenances.map((event: Maintenance) => new Maintenance(event))
      ),
      map((maintenances) =>
        maintenances.map((event: Maintenance) => this.remapMaintenance(event))
      ),
      tap((maintenances: Array<Maintenance>) => {
        maintenances.forEach((maintenance) => {
          const mnt = this.preventiveSubject.getValue();
          const idx = mnt.findIndex(
            (t: Maintenance) => t._id === maintenance._id
          );
          this.preventiveSubject.next(mnt.set(idx, maintenance));
        });
      })
    );
  }

  calculateNextMaintenanceDate = (
    period: number,
    nextMaintenanceDate: Moment
  ) => {
    return moment(
      moment(nextMaintenanceDate)
        .add(period, "days")
        .add(this.commonService.getTimezoneOffset(), "minutes")
        .tz(this.commonService.getTimezone())
        .format("YYYY-MM-DD HH:mm:ss")
    );
  };

  getMaintenancesBasedOnDate(
    start: Moment,
    end: Moment
  ): Promise<Maintenance[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = [];
        const maintenances = this.preventiveSubject
          .getValue()
          .toArray()
          .filter((m) => !m.isPMCalender);
        await Bluebird.mapSeries(maintenances, async (maintenance) => {
          let nextMaintenance = this.calculateNextMaintenanceDate(
            maintenance.getPeriod(),
            maintenance.nextMaintenance ? maintenance.nextMaintenance : moment()
          );
          while (nextMaintenance.isSameOrBefore(end)) {
            if (nextMaintenance.isSameOrAfter(start)) {
              response.push({
                ...maintenance,
                nextMaintenance: nextMaintenance,
              });
            }
            nextMaintenance = this.calculateNextMaintenanceDate(
              maintenance.getPeriod(),
              nextMaintenance
            );
          }
        });
        resolve(response);
      } catch (error) {
        console.log("getMaintenancesBasedOnDate", error);
        reject(error);
      }
    });
  }
}
