import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";

import { ICellEditorAngularComp } from "ag-grid-angular";
import { isObject } from "lodash";

@Component({
    selector: 'editor-cell',
    template: `
        <div #container class="search_select">
            <ngx-select
                [allowClear]="true"
                autocomplete="off"
                [items]="values"
                [placeholder]='"maintenance.form-labels.SELECT-PLACEHOLDER" | translate'
                [(ngModel)]="value"
                [disabled]="disable"
                >
            </ngx-select>
        </div>
    `,
    styles: [`
        .search_select, .search_select .ngx-select{
            min-width: 280px;
        }
        @media (max-width:1199px){
            .search_select, .search_select .ngx-select{
                min-width: 212px;
            }
        }
        .search_select ngx-select{
            width: 100%;
        }
        .search_select .ngx-select__search{
            min-width: 280px;
        }
        @media (max-width:1199px){
            .search_select .ngx-select__search{
                min-width: 212px;
            }
        }
    `]
})
export class SelectDropdownEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public values: any;
    public value: any;
    public model: any;
    public disable: any = false;
    public change: Function = null;
    @ViewChild('container', { read: ViewContainerRef }) public container;
    public happy: boolean = false;

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {

    }

    agInit(params: any,): void {
        this.params = params;
        if(this.params.colDef.section === "tools" && params.colDef.field == 'tool' && !params.data.isSubSystem){
            this.disable = true;
        }
        if (params.values && params.values.length > 0 && typeof params.values[0].company != 'undefined' && params.data.company) {
            this.values = params.values.filter(val => val.company && val.company._id == params.data.company._id);
        } else {
            this.values = params.values;
        }
        if (this.params.colDef.section == 'events' && params.colDef.field == 'worker' && isObject(params.data[params.colDef.field])) {
            this.value = typeof params.data[params.colDef.field] != 'undefined' ? params.data[params.colDef.field]['_id'] : '';
        } else {
            this.value = typeof params.data[params.colDef.field] != 'undefined' ? params.data[params.colDef.field] : '';
        }
        this.change = params.onChange || null;
    }

    getValue(): any {
        return this.value;
    }

    isPopup(): boolean {
        return true;
    }
}