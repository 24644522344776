import { ROLES } from "../../core/constants/enums/enums";
import { Department } from "../../core/interfaces/department";
import { Site } from "../../core/interfaces/site";

export interface UserEmail {
  email?: string;
  company?: string;
  _id?: string;
  username?: string;
  otp?: string;
  verified?: boolean;
  resend?: number;
  resendText?: string | null;
}

export class User {
  _id?: string;
  id_num: Number;
  company?: {
    _id: string,
    name: string,
    landingPage: string,
    verified: boolean,
    dailyWorkHours: {
      start: string,
      end: string
    },
    daysOff: [],
    timeFormat: string,
    timezone: string,
    shortDateFormat: string,
    longDateFormat: string,
    whatsAppMessageOn: [],
    whatsappMessageFormat: [],
    whatsAppGroupId: string,
    onboardingWizard: [],
    dayLight: boolean,
    dayLightManual: string,
    whatsAppFrom: string,
    wpDefaultTool: string | null,
    wpDefaultUser: string | null,
    slaTime: string | null,
    autoSignOut: boolean,
    autoSignoutAfterMinutes: number | null,
    showRecentlyDoneEventInHours: number,
    isActiveDirectory?: boolean,
    firstDayOfWeek?: number,
    freemiumPermissions: {
      signature: boolean,
      limitedIP: boolean,
      whatsApp: boolean,
      smartDropDown: boolean,
      bigTeam: boolean,
      sla: boolean,
      auditTrail: boolean,
      otp: boolean,
      otpByWhatsApp: boolean,
      otpByEmail: boolean,
      proceduresAndTasks: boolean,
      inventory: boolean,
      smartSchedule: boolean,
      measurements: boolean,
      pmCalender: boolean,
      api: boolean,
      automaticAssistant: boolean,
      drawingMode: boolean,
      allowSignAllTasks: boolean,
      hideDataInGraphs: boolean,
      hideDataInGraphsDefault: boolean,
      autoAdjustStartTimeBasedOnEstimation: boolean,
      quantityWaQuery: boolean,
      allowSmartAnswering: boolean,
      allowCollaboardativeMaintenance?: boolean,
      moderator: boolean,
      hideChecklistPreview: boolean,
      scheduledReports: boolean,
      hideTasksFromFm: boolean,
      saveEventAsOpen: boolean,
      calendar: boolean,
      qaSignature: boolean,
      fmEventQa: boolean,
      pmEventQa: boolean,
      eventTypeQa?: string[],
      safetySignature: boolean,
      fmEventSafety: boolean,
      pmEventSafety: boolean,
      eventTypeSafety?: string[],
      openEventFromTask: boolean,
      translateAlwaysToggle: boolean,
      openEventType?: number | null,
      openEventId?: string | null,
      subSystems: boolean,
      connectedEvents: boolean,
      useToolAsWarehouse: boolean,
      groupPmUpdate: boolean,
    }
  };
  remember?: string;
  email?: string;
  username?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  roles?: [string?];
  createdAt?: Date;
  updatedAt?: Date;
  language: String;
  fullName: String;
  displayName: string;
  noEmailNotification: boolean;
  dontAssignEvents: boolean;
  isTemporaryPassword: boolean;
  landingPage: string;
  image?: string;
  department?: any;
  departmentObject?: Department[];
  dashboardTimePicker?: any;
  activeEventTimePicker?: any;
  isDeleted?: boolean;
  dashboard?: {
    'kpis': [string?],
    'charts': [string?]
  };
  isWorker?: boolean;
  site?: any;
  siteObject?: Site[];
  suspendMessageLogout?: boolean;
  suspendMessageWorkingHours?: boolean;
  settings: {
    wrongTimeInterval?:string,
    failTime?:string,
    freezingPeriod?:string,
    wrongTimeIntervalOtp?:string,
    failTimeOtp?:string,
    freezingPeriodOtp?:string,
    resendOtpTime?:string,
    signupLimit?:string,
    version?:string,
  };
  columnStates?:any;
  url: string;
  blockedForWhatsapp: boolean;
  lastLoggedout?: Date;
  workShift?: string;

  constructor(options: {
    _id?: string,
    id_num?: Number,
    company?: {
      _id: string,
      name: string,
      landingPage: string,
      verified: boolean,
      dailyWorkHours: {
        start: string,
        end: string
      }
      daysOff: [],
      timeFormat: string,
      timezone: string,
      shortDateFormat: string,
      longDateFormat: string,
      whatsAppMessageOn: [],
      whatsappMessageFormat: [],
      whatsAppGroupId: string,
      onboardingWizard: [],
      dayLight: boolean,
      dayLightManual: string,
      whatsAppFrom: string,
      wpDefaultTool: string | null,
      wpDefaultUser: string | null,
      slaTime: string | null,
      autoSignOut: boolean,
      autoSignoutAfterMinutes: number | null,
      showRecentlyDoneEventInHours: number,
      isActiveDirectory?: boolean,
      firstDayOfWeek?: number,
      freemiumPermissions: {
        signature: boolean,
        limitedIP: boolean,
        whatsApp: boolean,
        smartDropDown: boolean,
        bigTeam: boolean,
        sla: boolean,
        auditTrail: boolean,
        otp: boolean,
        otpByWhatsApp: boolean,
        otpByEmail: boolean,
        proceduresAndTasks: boolean,
        inventory: boolean,
        smartSchedule: boolean,
        measurements: boolean,
        pmCalender: boolean,
        api: boolean,
        automaticAssistant: boolean,
        drawingMode: boolean,
        allowSignAllTasks: boolean,
        hideDataInGraphs: boolean,
        hideDataInGraphsDefault: boolean,
        autoAdjustStartTimeBasedOnEstimation: boolean,
        quantityWaQuery: boolean,
        allowSmartAnswering: boolean,
        moderator: boolean,
        hideChecklistPreview: boolean,
        scheduledReports: boolean,
        hideTasksFromFm: boolean,
        saveEventAsOpen: boolean,
        calendar: boolean,
        qaSignature: boolean,
        fmEventQa: boolean,
        pmEventQa: boolean,
        eventTypeQa?: string[],
        safetySignature: boolean,
        fmEventSafety: boolean,
        pmEventSafety: boolean,
        eventTypeSafety?: string[],
        openEventFromTask: boolean,
        translateAlwaysToggle: boolean,
        openEventType?: number | null,
        openEventId?: string | null,
        subSystems: boolean,
        connectedEvents: boolean,
        useToolAsWarehouse: boolean,
        groupPmUpdate: boolean,
      },
    },
    remember?: string,
    email?: string,
    username?: string,
    password?: string,
    firstName?: string,
    lastName?: string,
    phone?: string,
    roles?: [string?],
    createdAt?: Date,
    updatedAt?: Date,
    language?: String,
    displayName?: string,
    noEmailNotification?: boolean,
    dontAssignEvents?: boolean,
    isTemporaryPassword?: boolean,
    landingPage?: string,
    image?: string,
    department?: any,
    departmentObject?: Department[],
    dashboardTimePicker?: any,
    activeEventTimePicker?: any,
    isDeleted?: boolean,
    dashboard?: {
      'kpis': [string?],
      'charts': [string?]
    },
    isWorker?: boolean,
    site?: any,
    siteObject?: Site[],
    suspendMessageLogout?: boolean,
    suspendMessageWorkingHours?: boolean,
    settings: {
      wrongTimeInterval?:string,
      failTime?:string,
      freezingPeriod?:string,
      wrongTimeIntervalOtp?:string,
      failTimeOtp?:string,
      freezingPeriodOtp?:string,
      resendOtpTime?:string,
      signupLimit?:string,
      version?:string,
    },
    columnStates?: any,
    url?: string,
    blockedForWhatsapp: boolean,
    lastLoggedout?: Date,
    workShift?: string,
  }) {
    this._id = options._id;
    this.id_num = options.id_num || null;
    this.company = options.company || null;
    this.remember = options.remember || null;
    this.email = options.email || null;
    this.password = options.password || null;
    this.firstName = options.firstName || null;
    this.lastName = options.lastName || null;
    this.phone = options.phone || null;
    this.roles = options.roles || [];
    this.createdAt = options.createdAt;
    this.updatedAt = options.updatedAt;
    this.language = options.language ? options.language : "English";
    this.fullName = `${options.firstName || ""} ${options.lastName || ""}`;
    this.displayName = options.displayName || null;
    this.noEmailNotification = !!options.noEmailNotification;
    this.dontAssignEvents = !!options.dontAssignEvents;
    this.isTemporaryPassword = options.isTemporaryPassword || false;
    this.image = options.image || null;
    this.landingPage = options.landingPage || "";
    this.department = options.department || null;
    this.departmentObject = options.departmentObject || null;
    this.dashboardTimePicker = options.dashboardTimePicker || null;
    this.activeEventTimePicker = options.activeEventTimePicker || null;
    this.isDeleted = options.isDeleted || false;
    this.dashboard = options.dashboard || {
      'kpis': [],
      'charts': []
    };
    this.isWorker = options.isWorker || false;
    this.site = options.site || null;
    this.siteObject = options.siteObject || null;
    this.suspendMessageLogout = options.suspendMessageLogout || false;
    this.suspendMessageWorkingHours = options.suspendMessageWorkingHours || false;
    this.settings = options.settings || null;
    this.columnStates = options.columnStates || {};
    this.url = options.url || null;
    this.blockedForWhatsapp = options.blockedForWhatsapp || false;
    this.lastLoggedout = options.lastLoggedout || null;
    this.workShift = options.workShift || null;
  }

  isRoot = () => this._id && this.roles.includes(ROLES.ROOT);
  isAdmin = () => this._id && this.roles.includes(ROLES.ADMIN);
  isAdminOrToolAdmin = () => this._id && (this.roles.includes(ROLES.ADMIN) || this.roles.includes(ROLES.TOOL_ADMIN));
}

export class LoginResponse {
  token: string;
  expiresIn: number;
  user: User;
  isDevConnected: boolean;
  allowedFileExtentions: string;
  allowedImageFileExtentions: string;
  isTestConnected: boolean;
  version: string;
  signupLimit: string;
  developerSMSNumber: string;
  customVariables?: any;
}
