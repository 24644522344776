import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilesStoreService } from '../../../core/services/files-store.service';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';

@Component({
  selector: 'app-select-file',
  templateUrl: './select-file.component.pug',
  styleUrls: ['./select-file.component.scss']
})
export class SelectFileComponent implements OnInit {

  observer: Observer<string>
  form: UntypedFormGroup;
  files$: Observable<{ id: string, text: string }[]> = this.filesStoreService.files$.pipe(
    map(files => files.filter(file => (file.entity === "Tool" || file.entity === "Setup-Wizard" || file.entity === "Custom Files") && !file.isDeleted).map(f => ({ id: f._id, text: `${f.id_num} - ${f.name}` })).toArray()),
  );
  constructor(
    public translationService: TranslationsStoreService,
    public bsModalRef: BsModalRef,
    public fb: UntypedFormBuilder,
    public filesStoreService: FilesStoreService,
  ) { }

  ngOnInit(): void {
    this.filesStoreService.load();
    this.form = this.fb.group({
      file: this.fb.control('', Validators.required),
    });
  }

  save = () => {
    if (this.form.valid) {
      this.bsModalRef.hide();
      this.observer.next(this.form.value.file);
    }
  }

}
