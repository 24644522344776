import { Injectable } from '@angular/core';
import { BASE_CHAT_GPT } from '../interfaces/general';
import { OpenaiBackendService } from './backend/openai.backend.service';

@Injectable({
  providedIn: 'root'
})
export class OpenaiStoreService {
  constructor(
    private backend: OpenaiBackendService
  ) { }

  fetchAllToolImages = (toolId: string) => {
    return this.backend.fetchAllToolImages(toolId);
  }

  ask = (search: string, tool: string) => {
    return this.backend.ask(search, tool);
  }

  simulator = (sentence: BASE_CHAT_GPT) => {
    return this.backend.simulator(sentence);
  }

}
