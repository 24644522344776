import { Injectable } from '@angular/core';
import { map, uniq, without } from 'lodash';
import { combineLatest } from 'rxjs';
import { CustomFieldsStoreService } from './custom-fields-store.service';
import { DepartmentsStoreService } from './departments-store.service';
import { DynamicDropdownStoreService } from './dynamic-dropdown-store.service';
import { EventLogsStoreService } from './event-logs-store.service';
import { EventsStoreService } from './events-store.service';
import { FilesStoreService } from './files-store.service';
import { MachineStatusesStoreService } from './machine-statuses-store.service';
import { MaintenanceTypesStoreService } from './maintenance-types-store.service';
import { PartsStoreService } from './parts-store.service';
import { PreventiveStoreService } from './preventive-store.service';
import { SignatureAuditTrailsService } from './signature-audit-trails.service';
import { SlaStoreService } from './sla-store.service';
import { TaskTransactionStoreService } from './task-transaction-store.service';
import { ToolsStoreService } from './tools-store.service';
import { TransactionssStoreService } from './transactions-store.service';
import { UserLogsStoreService } from './user-logs-store.service';
import { UsersStoreService } from './users-store.service';
import { AiHelperStoreService } from './ai-helper-store.service';
import { SECTION_NAME } from '../constants/enums/enums';

@Injectable({
  providedIn: 'root'
})
export class CustomSmartDropdownService {

  constructor(
    private toolsStoreService: ToolsStoreService,
    private preventiveStoreService: PreventiveStoreService,
    private eventsStoreService: EventsStoreService,
    private partsStoreService: PartsStoreService,
    private filesStoreService: FilesStoreService,
    private usersStoreService: UsersStoreService,
    private maintenanceTypesStoreService: MaintenanceTypesStoreService,
    private machineStatusesStoreService: MachineStatusesStoreService,
    private departmentsStoreService: DepartmentsStoreService,
    private dynamicDropdownStoreService: DynamicDropdownStoreService,
    private transactionssStoreService: TransactionssStoreService,
    private customFieldsService: CustomFieldsStoreService,
    private taskTransactionStoreService: TaskTransactionStoreService,
    private userLogsStoreService: UserLogsStoreService,
    private signatureAuditTrailsService: SignatureAuditTrailsService,
    private eventLogsStoreService: EventLogsStoreService,
    private aiHelperStoreService: AiHelperStoreService,
    private slaStoreService: SlaStoreService,
  ) { }

  getColumnValue = (ev, acc, curr) => {
    let value = "";
    const event = { ...ev }
    if (curr.valueFormatter) value = curr.valueFormatter({ data: event })
    else if (curr.valueGetter) value = curr.valueGetter({ data: event })
    else if (curr.field.includes('.')) {
      const props = curr.field.split('.');
      value = props.reduce((acc: any, curr: any) => acc ? acc[curr] : '', event);
    }
    else value = event[curr.field];
    return { ...acc, [curr.field]: value }
  }

  getCustomValues = (section: string, field: string, type: number = 1) => {
    return new Promise((resolve, reject) => {
      let data: any = [];
      if (section == 'tool') {
        let res = this.toolsStoreService.getTools();
        this.toolsStoreService.columns$.subscribe(columns => {
          if (res.length > 0) {
            data = res.map(ev => {
              return columns.reduce((acc, curr) => {
                return this.getColumnValue(ev, acc, curr);
              }, {});
            })
          }
          resolve(this.setSelectData(data, field, type));
        });
      } else if (section == 'pm') {
        let res = this.preventiveStoreService.getList().toArray();
        this.preventiveStoreService.columns$.subscribe(columns => {
          if (res.length > 0) {
            data = res.map(ev => {
              return columns.reduce((acc, curr) => {
                return this.getColumnValue(ev, acc, curr);
              }, {});
            })
          }
          resolve(this.setSelectData(data, field, type));
        });
      } else if (section == 'history') {
        let res = this.eventsStoreService.getEvents().toArray();
        this.eventsStoreService.columns$.subscribe(columns => {
          if (res.length > 0) {
            data = res.map(ev => {
              return columns.reduce((acc, curr) => {
                return this.getColumnValue(ev, acc, curr);
              }, {});
            })
          }
          resolve(this.setSelectData(data, field, type));
        });
      } else if (section == 'inventory') {
        this.partsStoreService.load().subscribe(() => {
          let res = this.partsStoreService.getList().toArray();
          this.partsStoreService.columns$.subscribe(columns => {
            if (res.length > 0) {
              data = res.map(ev => {
                return columns.reduce((acc, curr) => {
                  return this.getColumnValue(ev, acc, curr);
                }, {});
              })
            }
            resolve(this.setSelectData(data, field, type));
          });
        });
      } else if (section == 'files') {
        let res = this.filesStoreService.getList().toArray();
        this.filesStoreService.columns$.subscribe(columns => {
          if (res.length > 0) {
            data = res.map(ev => {
              return columns.reduce((acc, curr) => {
                return this.getColumnValue(ev, acc, curr);
              }, {});
            })
          }
          resolve(this.setSelectData(data, field, type));
        });
      } else if (section == 'users' || section == 'workers') {
        let res = this.usersStoreService.getList().toArray();
        if (section == 'users') {
          res = res.filter(u => !u.isDeleted);
        } else {
          res = res.filter(u => u.isWorker && !u.isDeleted);
        }
        this.usersStoreService.columns$.subscribe(columns => {
          if (res.length > 0) {
            data = res.map(ev => {
              return columns.reduce((acc, curr) => {
                return this.getColumnValue(ev, acc, curr);
              }, {});
            })
          }
          resolve(this.setSelectData(data, field, type));
        });
      } else if (section == 'pm-kind') {
        let res = this.maintenanceTypesStoreService.getList().toArray();
        this.maintenanceTypesStoreService.columns$.subscribe(columns => {
          if (res.length > 0) {
            data = res.map(ev => {
              return columns.reduce((acc, curr) => {
                return this.getColumnValue(ev, acc, curr);
              }, {});
            })
          }
          resolve(this.setSelectData(data, field, type));
        });
      } else if (section == 'machine-status') {
        let res = this.machineStatusesStoreService.getList().toArray();
        this.machineStatusesStoreService.columns$.subscribe(columns => {
          if (res.length > 0) {
            data = res.map(ev => {
              return columns.reduce((acc, curr) => {
                return this.getColumnValue(ev, acc, curr);
              }, {});
            })
          }
          resolve(this.setSelectData(data, field, type));
        });
      } else if (section == 'departments') {
        let res = this.departmentsStoreService.getList().toArray();
        this.departmentsStoreService.columns$.subscribe(columns => {
          if (res.length > 0) {
            data = res.map(ev => {
              return columns.reduce((acc, curr) => {
                return this.getColumnValue(ev, acc, curr);
              }, {});
            })
          }
          resolve(this.setSelectData(data, field, type));
        });
      }else{
        this.dynamicDropdownStoreService.getListByKey(section).subscribe(res => {
          combineLatest([this.dynamicDropdownStoreService.columns$, this.customFieldsService.get(section)])
          .subscribe(([cols, columns]) => {
            if (res.toArray().length > 0) {
              const fields = columns.toArray();
              const custom = this.customFieldsService.toColumnDef(fields, {
                format: this.customFieldsService.formatCellCustomField.bind(this)
              });
              data = res.toArray().map(ev => {
                return cols.concat(custom).reduce((acc, curr) => {
                  return this.getColumnValue(ev, acc, curr);
                }, {});
              })
            }
            resolve(this.setSelectData(data, field, type));
          });
        });
      }
    })
  }

  setSelectData = (data: [], field: string, type: number) => {
    let filteredData: any = data.filter((d: any) => !d.isDeleted);
    let selectedData: any = map(filteredData, field);
    selectedData = uniq(without(selectedData, undefined, null, ""));
    return selectedData.reduce((acc, key) => {
      if (type == 1) {
        acc.push({ id: key, text: key });
      } else {
        acc.push({ key: key, value: key });
      }
      return acc;
    }, []);
  }

  getCustomColumnName = (table, isSelect = true) => {
    return new Promise((resolve, reject) => {
      switch (table) {
        case 'tool':
          this.toolsStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case 'pm':
          this.preventiveStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case 'history':
          this.eventsStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case 'inventory':
          this.partsStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case 'files':
          this.filesStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case 'users':
        case 'workers':
          this.usersStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case 'pm-kind':
          this.maintenanceTypesStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case 'machine-status':
          this.machineStatusesStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case 'departments':
          this.departmentsStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case 'transaction':
          this.transactionssStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case 'task-transaction':
          this.taskTransactionStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case 'user-logs':
          this.userLogsStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case 'signature-at':
          this.signatureAuditTrailsService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case 'history-at':
          this.eventLogsStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case 'sla':
          this.slaStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        case SECTION_NAME.AI_HELPER:
          this.aiHelperStoreService.columns$.subscribe(data => {
            if (isSelect) resolve(this.reduceFieldsValue(data))
            else resolve(data)
          })
          break;
        default:
          resolve(null);
          break;
      }
    })
  }

  reduceFieldsValue = (data) => {
    return data.filter(d => !d.hide).reduce((acc, key) => {
      acc.push({ id: key.colId, text: key.headerName });
      return acc;
    }, []);
  }

}
