import { Injectable } from '@angular/core';
import { AuthService } from '../../routes/user/auth.service';
import { userInfo } from 'os';
import { SubscriptionPaymentsBackendService } from './backend/subscription-payments.backend.service';
import { BehaviorSubject, Observable, pipe } from 'rxjs';
import { List } from 'immutable';
import { SubscriptionPayment } from '../interfaces/subscription-payment';
import { tap, map, shareReplay } from 'rxjs/operators';
import { CompaniesStoreService } from './companies-store.service';
import FieldDefinition from '../constants/field-definitions/subscription-payments';
import { CustomFieldsStoreService } from './custom-fields-store.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPaymentsStoreService {

  private subscriptionPaymentsSubject = new BehaviorSubject(List([]));
  public readonly subscriptionPayments$: Observable<List<SubscriptionPayment>>;

  private loadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly loaded$: Observable<boolean>  = this.loadedSubject.asObservable();
  public columns$: Observable<any>;

  constructor(private backend: SubscriptionPaymentsBackendService,
    private customFieldsService: CustomFieldsStoreService,
    private auth: AuthService) {

    this.auth.user$.subscribe(user=>{
      if (!user || !user._id) {
        this.loadedSubject.next(false);
        this.subscriptionPaymentsSubject.next(List([]));
        return;
      }
      if(user.isRoot()) this.load();
    });

    this.subscriptionPayments$ = this.subscriptionPaymentsSubject.pipe(
      ///map(payments => payments.sort((a,b)=> new Date(b.endDate()).getTime() - new Date(a.endDate()).getTime())),
      shareReplay(1)
    )

    this.columns$ = this.auth.user$.pipe(
      map((user)=>{
        if (!user) return [];
        const defaults = FieldDefinition.FieldDefinition.filter(col=>col.name != "company.name" || user.isRoot());
        const col = this.customFieldsService.toColumnDef(defaults, {});
        return col;
      })
    );

  }

  load(){
    this.backend.list().pipe(
      tap(result=> {
        this.loadedSubject.next(true);
      })
    ).subscribe(result=>{
      this.subscriptionPaymentsSubject.next(List(result))
    })
  }

  create (data: SubscriptionPayment){
    return this.backend.create(data).pipe(
      map(payment => new SubscriptionPayment(payment)),
      tap(payment =>{
        this.subscriptionPaymentsSubject.next(this.subscriptionPaymentsSubject.getValue().unshift(payment));
      })
    )
  }

  cancel (data: SubscriptionPayment){
    return this.backend.cancel(data).pipe(tap(()=>{
      this.load();
    }))
  }

  update(data: SubscriptionPayment) {
    return this.backend.update(data)
      .pipe(tap(sub => {
        const payment = new SubscriptionPayment(sub);
        const subscriptionPayments = this.subscriptionPaymentsSubject.getValue();
        const idx = subscriptionPayments.findIndex((s: SubscriptionPayment) => s._id === data._id);
        this.subscriptionPaymentsSubject.next(subscriptionPayments.set(idx, payment));
      }));
  }

  sync(){
    return this.backend.sync().pipe(
      tap(()=>{
        this.load();
      })
    )
  }
}
